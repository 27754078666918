import {Dropdown, InputGroup} from "react-bootstrap";
import {ReactNode, useEffect, useReducer} from "react";

export interface PaginatedResponse<T> {
    data: T[]
    total_count: number
}

export interface MultiselectPaginatedDropdownProps<T> {
    empty: ReactNode
    // eslint-disable-next-line no-unused-vars
    single: (item: T) => ReactNode
    // eslint-disable-next-line no-unused-vars
    multiple: (items: T[]) => ReactNode
    // eslint-disable-next-line no-unused-vars
    fetch: (limit: number, offset: number) => Promise<PaginatedResponse<T>>

    // eslint-disable-next-line no-unused-vars
    itemKey: (item: T) => any

    // eslint-disable-next-line no-unused-vars
    label: (item: T) => ReactNode
    // eslint-disable-next-line no-unused-vars
    onChange?: (items: T[]) => void
    multiselect?: boolean
}

interface MultiselectPaginatedDropdownState<T> {
    selected: T[]
    items: T[]
    offset: number
    limit: number
    total_count: number | null
    error: Error | null
}

type MultiselectPaginatedDropdownEvent<T> = {
    type: 'NEXT_DATA',
    data: PaginatedResponse<T>
} | {
    type: 'NEXT_PAGE'
} | {
    type: 'SELECT',
    item: T
} | {
    type: 'ERROR',
    error: Error
}

function newState<T>(): MultiselectPaginatedDropdownState<T> {
    return {
        selected: [],
        items: [],
        offset: 0,
        limit: 10,
        error: null,
        total_count: null
    }
}

export function MultiselectPaginatedDropdown<T>(props: MultiselectPaginatedDropdownProps<T>) {
    const multiselect = props.multiselect === undefined || props.multiselect;
    const [state, dispatchState] = useReducer((state: MultiselectPaginatedDropdownState<T>, event: MultiselectPaginatedDropdownEvent<T>) => {
        if (event.type === "NEXT_PAGE") {
            return {...state, offset: state.offset + state.limit}
        } else if (event.type === "NEXT_DATA") {
            const newItems = event.data.data.filter(e => state.items.find(x => props.itemKey(e) === props.itemKey(x)) === undefined)
            return {...state, items: [...state.items, ...newItems], total_count: event.data.total_count}
        } else if (event.type === "SELECT") {
            const isSelected = state.selected.find(e => props.itemKey(e) === props.itemKey(event.item)) !== undefined
            if (multiselect) {
                const newSelected = isSelected ?
                    state.selected.filter(e => props.itemKey(e) !== props.itemKey(event.item)) :
                    [...state.selected, event.item]
                return {...state, selected: newSelected}
            } else {
                return { ...state, selected: isSelected ? [] : [ event.item ] }
            }
        } else if (event.type === "ERROR") {
            return {...state, error: event.error}
        }
        return state
    }, newState<T>())
    useEffect(() => {
        if (props.onChange !== undefined) {
            props.onChange(state.selected)
        }
    }, [state.selected])
    useEffect(() => {
        props.fetch(state.limit, state.offset)
            .then(e => dispatchState({ type: 'NEXT_DATA', data: e }))
            .catch(e => dispatchState({ type: 'ERROR', error: e }))
    }, [state.limit, state.offset])
    return (
        <Dropdown autoClose='outside' drop='down'>
            <Dropdown.Toggle className='cursor-pointer' as={InputGroup.Text} style={{flex: '1 1 auto'}}> {/* some piece of magic to stretch toggle */}
                {state.selected.length === 0 ? props.empty : state.selected.length === 1 ? props.single(state.selected[0]) : props.multiple(state.selected)}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{width: '50%'}}>
                <Dropdown.Header>Groups</Dropdown.Header>
                {
                    state.items.map(item => {
                        const isSelected = state.selected.find(e => props.itemKey(e) === props.itemKey(item)) !== undefined
                        return (
                            <Dropdown.Item className='cursor-pointer' key={props.itemKey(item)}>
                                <div className='form-check' onClick={(e) => { e.stopPropagation(); dispatchState({ type: 'SELECT', item: item })} }>
                                    <input
                                        readOnly
                                        className='form-check-input'
                                        type={multiselect ? 'checkbox' : 'radio'}
                                        checked={isSelected} />
                                    <label className='form-check-label'>
                                        {props.label(item)}
                                    </label>
                                </div>
                            </Dropdown.Item>
                        )
                    })
                }
                {
                    state.total_count && state.total_count > state.items.length ?
                        <Dropdown.Item onClick={() => dispatchState({ type: 'NEXT_PAGE' })}>Show more...</Dropdown.Item> :
                        <Dropdown.Header>{state.items.length} items</Dropdown.Header>
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}