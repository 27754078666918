import {CallState} from "./Calls";
import {Button, ButtonGroup, Dropdown, DropdownButton} from "react-bootstrap";

export type PaginationDirection = 'next' | 'prev'

export interface PaginationDirectionControlsProps<T> {
    call: CallState<T, any>,
    show: boolean,
    // eslint-disable-next-line no-unused-vars
    totalCountFn: (data: T) => number,
    // eslint-disable-next-line no-unused-vars
    onPaginationControl?: (direction: PaginationDirection) => void,
    // eslint-disable-next-line no-unused-vars
    onPaginationLimit?: (limit: number) => void
}
export function PaginationDirectionControls<T>(props: PaginationDirectionControlsProps<T>) {
    if (!props.show) {
        return null
    }
    const onPaginationControl = (direction: PaginationDirection) => {
        if (props.onPaginationControl !== undefined) {
            props.onPaginationControl(direction)
        }
    }
    const onPaginationLimit = (limit: number) => {
        if (props.onPaginationLimit !== undefined) {
            props.onPaginationLimit(limit)
        }
    }
    const totalCount = props.call.data === null ? 0 : props.totalCountFn(props.call.data);
    const canPrev = props.call.pagination.offset > 0;
    const canNext = props.call.data === null || props.call.pagination.offset + props.call.pagination.limit < totalCount;
    return (
        <div className='pagination-wrapper'>
            {totalCount > 0 ? <div className='pagination-hint'>
                <span>{props.call.pagination.offset + 1}-{Math.min(totalCount, props.call.pagination.offset + props.call.pagination.limit + 1)} of {totalCount}</span>
            </div> : null}
            <ButtonGroup size='sm'>
                <Button variant='outline-primary' onClick={() => onPaginationControl('prev')} disabled={!canPrev}>Prev</Button>
                <DropdownButton title={props.call.pagination.limit} as={ButtonGroup} variant='outline-primary'>
                    {props.call.pagination.limits.map(limit => <Dropdown.Item onClick={() => onPaginationLimit(limit)} disabled={limit === props.call.pagination.limit} key={`limit-${limit}`}>{limit}</Dropdown.Item>)}
                </DropdownButton>
                <Button variant='outline-primary' onClick={() => onPaginationControl('next')} disabled={!canNext}>Next</Button>
            </ButtonGroup>
        </div>
    )
}
