import {createContext, ReactNode, useContext, useReducer} from "react";
import {useApi} from "../../Core/ApiContextProvider";
import {callDispatch, CallState, CallUpdate, newCall} from "../../Utils/Calls";
import {AddTaskRequest, AddTaskResponse, TaskData} from "../../Core/Model";

export interface CreateTaskDataContext {
    valid: boolean
    data: TaskData | null
}

export interface CreateTaskContextApi {
    create: CallState<AddTaskResponse>
    data: CreateTaskDataContext
    // eslint-disable-next-line no-unused-vars
    dispatchData: (data: TaskData) => void
    dispatchInvalid: () => void
    // eslint-disable-next-line no-unused-vars
    createTask: (request: AddTaskRequest) => void
}

export const CreateTaskContext = createContext<CreateTaskContextApi | null>(null)

export const useCreateTask = () => useContext(CreateTaskContext) ?? {} as never

export interface CreateTaskContextProviderProps {
    children: ReactNode
}

interface CreateTaskState {
    create: CallState<any>
    data: { valid: boolean, data: TaskData | null }
}

type CreateTaskEvent = {
    type: 'CALL_UPDATE',
    call: 'create',
    update: CallUpdate
} | {
    type: 'DATA',
    valid: boolean,
    data: TaskData | null
}

function newState(): CreateTaskState {
    return {
        create: newCall(),
        data: {
            valid: false,
            data: null
        }
    }
}

export const CreateTaskContextProvider = (props: CreateTaskContextProviderProps) => {
    const api = useApi()
    const [state, dispatchState] = useReducer((state: CreateTaskState, event: CreateTaskEvent) => {
        switch (event.type) {
            case "CALL_UPDATE": {
                switch (event.call) {
                    case "create": {
                        return { ...state, create: callDispatch(state.create, event.update) }
                    }
                }
                break;
            }
            case 'DATA': {
                return { ...state, data: { valid: event.valid, data: event.data } }
            }
        }
        return state
    }, newState())
    const createTask = (request: AddTaskRequest) => {
        dispatchState({ type: 'CALL_UPDATE', call: 'create', update: { type: 'LOADING', message: 'Creating request...' } })
        dispatchState({ type: 'CALL_UPDATE', call: 'create', update: { type: 'LOADING', message: 'Creating a task...' } })
        api.taskCreate(request)
            .then(e => dispatchState({ type: 'CALL_UPDATE', call: 'create', update: { type: 'DATA', data: e } }))
            .catch(e => dispatchState({ type: 'CALL_UPDATE', call: 'create', update: { type: 'ERROR', error: e } }))
    }
    const data: CreateTaskDataContext = {
        valid: state.data.valid,
        data: state.data.data
    }
    const context: CreateTaskContextApi = {
        create: state.create,
        data: data,
        dispatchData: (data) => dispatchState({ type: 'DATA', valid: true, data: data }),
        dispatchInvalid: () => dispatchState({ type: 'DATA', valid: false, data: null }),
        createTask: createTask
    }
    return(
        <CreateTaskContext.Provider value={context}>
            {props.children}
        </CreateTaskContext.Provider>
    )
}