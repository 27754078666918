import {Modal} from "react-bootstrap";
import {GroupDetails} from "./GroupDetails";

export interface GroupDetailsModalProps {
    groupId: number,
    onClose: () => void
}

export const GroupDetailsModal = (props: GroupDetailsModalProps) => {
    return (
        <Modal fullscreen={'lg-down'} size='lg' show={true}>
            <Modal.Body>
                <GroupDetails groupId={props.groupId} closeType={'close'} onClose={props.onClose} />
            </Modal.Body>
        </Modal>
    )
}