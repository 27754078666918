import {GroupDetails} from "./GroupDetails";
import {Navigate, useNavigate, useParams} from "react-router-dom";

export const GroupDetailsPage = () => {
    const navigate = useNavigate()
    const {groupId} = useParams()
    if (groupId === null || groupId === undefined) {
        return <Navigate to='/' />
    }

    const id = Number(groupId)
    if (Number.isNaN(id)) {
        return <Navigate to='/' />
    }
    return (
        <GroupDetails groupId={id} closeType={'back'} onClose={() => navigate(-1)} />
    )
}