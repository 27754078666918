import {BotGroup, RemoveBotGroupResponse} from "../Core/Model";
import {useReducer} from "react";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {useApi} from "../Core/ApiContextProvider";
import {Button, Form, FormGroup, Modal, Spinner} from "react-bootstrap";
import {ExclamationCircle} from "react-bootstrap-icons";

export interface DeleteGroupProps {
    group: BotGroup,
    onClose: () => void
}

export const DeleteGroupModal = (props: DeleteGroupProps) => {
    const api = useApi()
    const [state, dispatchState] = useReducer((state: CallState<RemoveBotGroupResponse>, event: CallUpdate) => {
        return callDispatch(state, event)
    }, newCall<RemoveBotGroupResponse>())
    const onClose = () => {
        props.onClose()
    }
    const onDelete = () => {
        dispatchState({ type: 'LOADING', message: `Removing ${props.group.name}...` })
        api.groupsDelete(props.group.id)
            .then(e => dispatchState({ type: 'DATA', data: e }))
            .catch(e => dispatchState({ type: 'ERROR', error: e }))
    }
    const getContent = () => {
        if (state.loading) {
            return (
                <div className='loading-wrapper'>
                    <Spinner/>
                    {state.message !== null && <span className='loading-message'>
                        {state.message}
                   </span>}
                </div>
            )
        }
        if (state.error) {
            return (
                <div className='error-message'>
                    <ExclamationCircle size='1.2em' color='red' />
                    <div>
                        Failed to delete group <b>{props.group.name}</b>
                        <div>{state.error.message}</div>
                    </div>
                </div>
            )
        }
        if (state.data) {
            return (
                <div className='success-message'>
                    <div>Group <Button disabled={true} size='sm' variant='outline-success'>{state.data.removed_group_id}</Button> successfully deleted</div>
                </div>
            )
        }
        return (
            <>
                <FormGroup>
                    <Form.Text>Are you sure you want to delete group <b>{props.group.name}</b>?</Form.Text>
                </FormGroup>
            </>
        )
    }
    const getFooter = () => {
        if (state.error) {
            return (
                <>
                    <Button variant='danger' onClick={onDelete}>Try again</Button>
                    <Button onClick={onClose} variant='outline-danger'>Close</Button>
                </>
            )
        }
        if (state.error || state.data) {
            return (
                <Button variant='primary' onClick={onClose}>
                    Close
                </Button>
            )
        }
        if (state.loading) {
            return null
        }
        return (
            <>
                <Button onClick={onDelete} variant='danger'>Yes, delete</Button>
                <Button onClick={onClose} variant='outline-danger'>No, close</Button>
            </>
        )
    }
    return (
        <Modal show={true}>
            <Modal.Header>
                <Modal.Title>Delete group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getContent()}
            </Modal.Body>
            <Modal.Footer>
                {getFooter()}
            </Modal.Footer>
        </Modal>
    )
}