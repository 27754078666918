import {TaskInfo} from "../Core/Model";
import {Badge, Button, Modal, Spinner} from "react-bootstrap";
import {useApi} from "../Core/ApiContextProvider";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {useReducer} from "react";
import {CheckCircle} from "react-bootstrap-icons";
import {useNavigate} from "react-router-dom";

export interface TaskInterruptModalProps extends TaskInterruptProps { }

export const TaskInterruptModal = (props: TaskInterruptModalProps) => {
    return (
        <Modal show={true}>
            <Modal.Header>
                Interrupt task
            </Modal.Header>
            <Modal.Body>
                <TaskInterrupt {...props} />
            </Modal.Body>
        </Modal>
    )
}

export interface TaskInterruptProps {
    task: TaskInfo,
    onClose: () => void
}

interface TaskInterruptState {
    interrupt: CallState<boolean>
}

type TaskInterruptEvent =
    {
        type: 'CALL_UPDATE',
        call: 'interrupt',
        data: CallUpdate
    };

function newState(): TaskInterruptState {
    return { interrupt: newCall() }
}

export const TaskInterrupt = ({ task, onClose }: TaskInterruptProps) => {
    const api = useApi()
    const navigate = useNavigate()
    const [state, dispatchState] = useReducer((state: TaskInterruptState, event: TaskInterruptEvent) => {
        if (event.type === 'CALL_UPDATE') {
            switch (event.call) {
                case 'interrupt':
                    return { ...state, interrupt: callDispatch(state.interrupt, event.data) }
            }
        }
        return state
    }, newState())
    const onInterrupt = () => {
        dispatchState({ type: 'CALL_UPDATE', call: 'interrupt', data: { type: 'LOADING', message: 'Interrupting...' } })
        api.taskInterrupt(task.task_id)
            .then(e => dispatchState({ type: 'CALL_UPDATE', call: 'interrupt', data: { type: 'DATA', data: true } }))
            .catch(e => dispatchState({ type: 'CALL_UPDATE', call: 'interrupt', data: { type: 'ERROR', error: e } }))
    }
    return (
        <div className='interrupt-wrapper'>
            <div>Are you sure you want to <span className='text-danger'>interrupt</span> task {task.task_id} of type <Badge bg={task.task_type_bg}>{task.task_type_name}</Badge>?</div>
            {state.interrupt.loading ?
                <div className='loading-wrapper'>
                    <Spinner />
                    {state.interrupt.message !== null && <span className='loading-message'>
                        {state.interrupt.message}
                    </span>}
                </div> :
                state.interrupt.data !== null ?
                    <div className='interrupt-success-wrapper'>
                        <div className='success-message' style={{ marginTop: '12px' }}>
                            <CheckCircle size='1.2em' color='green' />
                            <span>
                                Task <Button onClick={() => navigate(`/task/${task.task_id}`)} variant='outline-success' size='sm'>{task.task_id}</Button> successfully interrupted!
                            </span>
                        </div>
                        <Button variant='outline-success' onClick={onClose}>Close</Button>
                    </div>:
                <div className='interrupt-actions'>
                <Button variant='danger' onClick={onInterrupt}>Yes, interrupt</Button>
                <Button variant='primary' onClick={() => onClose()}>No, close</Button>
            </div>}
        </div>
    )
}