import {ChangeEvent, useEffect, useState} from "react";
import {FieldState} from "../../Utils/FieldState";
import {FormControl, InputGroup} from "react-bootstrap";
import {SimpleTooltip} from "../../Utils/SimpleTooltip";
import {CheckLg, XLg} from "react-bootstrap-icons";
import {useCreateTask} from "./CreateTaskContext";

export const CreateSetUsernameTask = () => {
    const createTask = useCreateTask()

    const [usernameValid, setUsernameValid] = useState<FieldState<string>>({ valid: false, message: 'You need to enter username' })
    const onUsernameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim()
        if (value.length === 0) {
            setUsernameValid({ valid: false, message: 'You need to enter username' })
            return
        }
        setUsernameValid({ valid: true, message: 'Ok', data: value })
    }

    useEffect(() => {
        if (usernameValid.valid) {
            createTask.dispatchData({ username: usernameValid.data })
        } else {
            createTask.dispatchInvalid()
        }
    }, [usernameValid])

    return (
        <div>
            <InputGroup>
                <InputGroup.Text>Username</InputGroup.Text>
                <FormControl onChange={onUsernameChange} type='text'/>
                <InputGroup.Text>
                    <SimpleTooltip tooltip={usernameValid.message}>
                        {usernameValid.valid ? <CheckLg color='green'/> : <XLg color='red'/>}
                    </SimpleTooltip>
                </InputGroup.Text>
            </InputGroup>
        </div>
    )
}