import {Button, FormControl, FormGroup, InputGroup, Spinner} from "react-bootstrap";
import {CheckLg, Pencil, XLg} from "react-bootstrap-icons";
import './Styles/Editable.css'
import {ChangeEvent, useReducer} from "react";
import {SimpleTooltip} from "./SimpleTooltip";

enum EditableMode {
    EDIT = 'edit',
    VIEW = 'view'
}

export interface EditableProps {
    value: string | null
    multiline: boolean
    allowBlank: boolean
    // eslint-disable-next-line no-unused-vars
    onSave: (value: string | null) => Promise<string | null>
}

interface EditableState {
    value: string | null
    originalValue: string | null
    mode: EditableMode
    saving: boolean
    error: string | null
    validationError: string | null
}

type EditableEvent = {
    type: 'EDIT'
} | {
    type: 'CANCEL'
} | {
    type: 'TEXT',
    value: string | null
} | {
    type: 'SET_SAVING',
    saving: boolean,
    value?: string | null,
    originalValue?: string | null,
    mode?: EditableMode
} | {
    type: 'ERROR',
    message: string | null,
    value?: string | null,
    originalValue?: string | null
}

function newState(props: EditableProps): EditableState {
    return {
        value: props.value,
        originalValue: props.value,
        mode: EditableMode.VIEW,
        saving: false,
        error: null,
        validationError: null
    }
}

export const Editable = (props: EditableProps) => {
    const [state, dispatchState] = useReducer((state: EditableState, event: EditableEvent) => {
        switch (event.type) {
            case "EDIT": {
                return { ...state, mode: EditableMode.EDIT, originalValue: state.value }
            }
            case "CANCEL": {
                return { ...state, mode: EditableMode.VIEW, value: state.originalValue, error: null }
            }
            case 'TEXT': {
                const validationError = (event.value === null || event.value.length === 0) && !props.allowBlank ?
                    'Must not be empty' : null;
                return { ...state, value: event.value, error: null, validationError: validationError }
            }
            case 'SET_SAVING': {
                const value = event.value === undefined ? state.value : event.value
                const originalValue = event.originalValue === undefined ? state.originalValue : event.originalValue
                const mode = event.mode === undefined ? state.mode : event.mode
                return { ...state, saving: event.saving, value: value, originalValue: originalValue, mode: mode }
            }
            case 'ERROR': {
                const value = event.value === undefined ? state.value : event.value
                const originalValue = event.originalValue === undefined ? state.originalValue : event.originalValue
                return { ...state, error: event.message, value: value, originalValue: originalValue }
            }
        }
        return state
    }, newState(props))
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value
        dispatchState({ type: 'TEXT', value: value })
    }
    const onSave = () => {
        const value = state.value
        const originalValue = state.originalValue
        if (value === originalValue) {
            dispatchState({ type: 'CANCEL' })
            return
        }
        dispatchState({ type: 'SET_SAVING', saving: true })
        if ((value === null || value.length === 0) && !props.allowBlank) {
            dispatchState({ type: 'ERROR', message: 'Field cannot be empty or blank' })
            return
        }
        props.onSave(value)
            .then(e => dispatchState({ type: 'SET_SAVING', saving: false, value: e, originalValue: e, mode: EditableMode.VIEW }))
            .catch(e => {
                dispatchState({ type: 'ERROR', message: e.message })
                dispatchState({ type: 'SET_SAVING', saving: false })
            })
    }
    const getContent = () => {
        const placeholder = state.value === null ? 'Unset' : state.value.length === 0 ? 'Empty' : null;
        const value = state.value ?? '';
        const originalValue = state.originalValue ?? ''
        if (state.mode === 'edit') {
            const save = <Button disabled={state.saving || state.validationError !== null} variant='primary' onClick={onSave}>Save</Button>
            const cancel = <Button disabled={state.saving} variant='outline-primary' onClick={() => dispatchState({ type: 'CANCEL' })}>Cancel</Button>
            const input = <FormControl placeholder={originalValue} isInvalid={state.error !== null} disabled={state.saving} as={props.multiline ? 'textarea' : undefined} onChange={onChange} rows={10} type='text' value={value} />
            const check = state.saving ?
                <InputGroup.Text><Spinner size='sm' /></InputGroup.Text> :
                <InputGroup.Text><SimpleTooltip tooltip={state.validationError ?? 'Ok'}>{state.validationError === null ? <CheckLg color='green' /> : <XLg color='red' />}</SimpleTooltip></InputGroup.Text>
            const error = <FormControl.Feedback type={state.error !== null ? 'invalid' : 'valid'}>{state.error}</FormControl.Feedback>
            if (props.multiline) {
                return (
                    <div className='d-flex flex-column'>
                        <div className='mb-1'>{input}{error}</div>
                        <div className='align-self-end'>
                            <InputGroup>{check}{save}{cancel}</InputGroup>
                        </div>
                    </div>
                )
            } else {
                return (
                    <FormGroup>
                        <InputGroup hasValidation>
                            {check}
                            {input}
                            {save}
                            {cancel}
                            {error}
                        </InputGroup>
                    </FormGroup>
                )
            }
        } else if (state.mode === 'view') {
            return (
                <>
                    {placeholder !== null ? <span className='editable-placeholder'>{placeholder}</span> : <span className='editable-value'>{value}</span>}
                    <Button size='sm' variant='link' onClick={() => dispatchState({ type: 'EDIT' })}>
                        <div className='icon-button'>
                            <Pencil />
                            <span>Edit</span>
                        </div>
                    </Button>
                </>
            )
        }
        return null;
    }
    return (
        <div className='editable-wrap'>
            <span className='editable-block'>
                {getContent()}
            </span>
        </div>
    )
}