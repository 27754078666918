import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {AddGroupBotsRequest, AddGroupBotsResponse} from "../Core/Model";
import {useReducer} from "react";
import {Button, FormGroup, Spinner} from "react-bootstrap";
import {BotsInput, BotsInputProvider, useBotsInput} from "../Bots/BotsInput";
import {inflateBots} from "../Utils/Utils";
import {useApi} from "../Core/ApiContextProvider";
import {CheckCircle, ExclamationCircle} from "react-bootstrap-icons";

export interface GroupBotsAddProps {
    groupId: number,
    // eslint-disable-next-line no-unused-vars
    onFinish: (added: boolean) => void
}

interface GroupBotAddState {
    add: CallState<AddGroupBotsResponse>
}

type GroupBotAddEvent = {
    type: 'CALL_UPDATE',
    call: 'add',
    update: CallUpdate
}

function newState(): GroupBotAddState {
    return {
        add: newCall()
    }
}

export const GroupBotsAdd = (props: GroupBotsAddProps) => {
    const api = useApi()
    const botsInput = useBotsInput()
    const [state, dispatchState] = useReducer((state: GroupBotAddState, event: GroupBotAddEvent) => {
        switch (event.type) {
            case "CALL_UPDATE": {
                switch (event.call) {
                    case "add": {
                        return { ...state, add: callDispatch(state.add, event.update) }
                    }
                }
                break;
            }
        }
        return state;
    }, newState())
    const onAddBots = () => {
        if (!botsInput.botsValid.valid) {
            return
        }
        const linearBots = inflateBots(botsInput.botsValid.data)
        if (linearBots.length == 0) {
            props.onFinish(false)
            return
        }
        const request: AddGroupBotsRequest = { bot_ids: linearBots }
        dispatchState({type: 'CALL_UPDATE', call: 'add', update: { type: 'LOADING', message: 'Adding bots...' }})
        api.groupBotsAdd(props.groupId, request)
            .then(e => dispatchState({type: 'CALL_UPDATE', call: 'add', update: { type: 'DATA', data: e }}))
            .catch(e => dispatchState({type: 'CALL_UPDATE', call: 'add', update: { type: 'ERROR', error: e }}))
    }
    const getContent = () => {
        if (state.add.loading) {
            return (
                <div className='loading-wrapper'>
                    <Spinner />
                    {state.add.message !== null && <span className='loading-message'>{state.add.message}</span>}
                </div>
            )
        }
        if (state.add.data) {
            return (
                <div className='d-flex flex-column'>
                    <div className='success-message mb-1'>
                        <CheckCircle size='1.2em' color='green' />
                        <span>
                            Bots are successfully added!
                        </span>
                    </div>
                    <Button className='align-self-end' onClick={() => props.onFinish(true)}>
                        Back to the list
                    </Button>
                </div>
            )
        }
        return (
            <>
                <FormGroup className="mb-3">
                    <BotsInputProvider value={botsInput}>
                        <BotsInput />
                    </BotsInputProvider>
                </FormGroup>
                {state.add.error ? <FormGroup className="mb-3">
                    <div className='error-message'>
                        <ExclamationCircle size='1.2em' color='red' />
                        <span>
                            Failed to add bots to group: {state.add.error.message}
                        </span>
                    </div>
                </FormGroup> : null}
                <FormGroup className="mb-3">
                    <Button disabled={!botsInput.botsValid.valid} onClick={() => onAddBots()}>
                        Add bots
                    </Button>
                </FormGroup>
            </>
        )
    }
    return (<div>{getContent()}</div>)
}