export type TimeUnit = 'ms' | 'sec' | 'min' | 'hours' | 'days'
export class DeltaInfo {

    milliseconds: number
    effective_unit: TimeUnit
    effective_value: number
    text: 'ago' | 'in the future' | 'now'

    constructor(milliseconds: number) {
        this.milliseconds = Math.abs(milliseconds)
        this.text = milliseconds >= 0 ? 'ago' : milliseconds < 0 ? 'in the future' : 'now'
        const [value, unit] = DeltaInfo.getEffectiveDelta(this.milliseconds)
        this.effective_value = value
        this.effective_unit = unit
    }

    toString() {
        return this.text === 'now' ? this.text : `${this.effective_value} ${this.effective_unit} ${this.text}`
    }

    static getEffectiveDelta(milliseconds: number): [number, TimeUnit] {
        if (milliseconds < 1000) {
            return [milliseconds, 'ms']
        }
        const seconds = milliseconds / 1000
        if (seconds < 60) {
            return [Math.round(seconds), 'sec']
        }
        const minutes = seconds / 60
        if (minutes < 60) {
            return [Math.round(minutes), 'min']
        }
        const hours = minutes / 60
        if (hours < 24) {
            return [Math.round(hours), 'hours']
        }
        const days = hours / 24
        return [Math.round(days), 'days']
    }
}