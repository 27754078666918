import {CreateBotCredentials} from "../Core/Model";
import React, {ChangeEvent, ClipboardEvent, FormEvent, KeyboardEvent, useRef, useState} from "react";
import {Button, FormControl, InputGroup} from "react-bootstrap";
import {PlusLg} from "react-bootstrap-icons";
import {undefinedIfEmpty} from "../Utils/Utils";

export interface BotCredentialsInputProps {
    enableAdd: boolean
    readonly?: boolean
    // eslint-disable-next-line no-unused-vars
    onAdd?: (credentials: CreateBotCredentials) => boolean
}
export const BotCredentialsInput = (props: BotCredentialsInputProps) => {
    const readonly = props.readonly !== undefined ? props.readonly : false
    const [disabled, setDisabled] = useState(true)
    const loginRef = useRef<HTMLInputElement | null>()
    const passwordRef = useRef<HTMLInputElement | null>()
    const sessionIdRef = useRef<HTMLInputElement | null>()
    const updateDisabledState = (login: string, password: string) => {
        setDisabled(login.length < 1 || password.length < 1)
    }
    const onLoginChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.endsWith(":")) {
            event.target.value = event.target.value.substring(0, event.target.value.length - 1)
            passwordRef.current?.focus()
            event.stopPropagation()
        }
    }
    const onPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.endsWith(":")) {
            event.target.value = event.target.value.substring(0, event.target.value.length - 1)
            sessionIdRef.current?.focus()
            event.stopPropagation()
        }
    }
    const onPasswordKey = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace') {
            const target = event.target as HTMLInputElement
            if (target.value.length === 0) {
                loginRef.current?.focus()
            }
        }
    }

    const onSessionIdKey = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Backspace') {
            const target = event.target as HTMLInputElement
            if (target.value.length === 0) {
                passwordRef.current?.focus()
            }
        }
    }

    const onLoginPaste = (event: ClipboardEvent<HTMLInputElement>) => {
        const data = event.clipboardData.getData('text/plain')
        if (data !== undefined && data !== null) {
            const currentLogin = loginRef.current;
            const currentPassword = passwordRef.current
            const currentSessionId = sessionIdRef.current
            const separateIndex = data.indexOf(":")
            const lastSeparateIndex = data.lastIndexOf(":")
            if (separateIndex > 0) {
                const login = data.substring(0, separateIndex)
                const password = lastSeparateIndex > separateIndex
                    ? data.substring(separateIndex+1, lastSeparateIndex)
                    : data.substring(separateIndex+1, data.length)
                const sessionId = lastSeparateIndex > separateIndex
                    ? data.substring(lastSeparateIndex+1, data.length)
                    : ''
                event.preventDefault()
                if (currentLogin !== undefined && currentLogin !== null && currentPassword != undefined && currentSessionId !== undefined && currentSessionId !== null) {
                    currentLogin.value = login
                    currentPassword.value = password
                    currentSessionId.value = sessionId
                    if (sessionId.length === 0) {
                        currentPassword.focus()
                    } else {
                        currentSessionId.focus()
                    }
                }
                updateDisabledState(login, password)
            } else if (currentLogin !== undefined && currentLogin !== null && currentPassword != undefined && currentSessionId !== undefined && currentSessionId !== null) {
                updateDisabledState(currentLogin?.value, currentPassword.value)
            }
        }
    }
    const onFormChange = (event: ChangeEvent<HTMLFormElement>) => {
        const login: HTMLInputElement = event.target.form[0]  as HTMLInputElement
        const password: HTMLInputElement = event.target.form[1] as HTMLInputElement
        updateDisabledState(login.value, password.value)
    }
    const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        if (!props.enableAdd) {
            return
        }
        const login: HTMLInputElement = (event.target as HTMLFormElement)[0] as HTMLInputElement
        const password: HTMLInputElement = (event.target as HTMLFormElement)[1] as HTMLInputElement
        const sessionId: HTMLInputElement = (event.target as HTMLFormElement)[2] as HTMLInputElement
        if (login.value.length > 0 && password.value.length > 0) {
            const onAdd = props.onAdd
            if (onAdd !== undefined && onAdd !== null) {
                const clearInput = onAdd({ login: login.value, password: password.value, session_key: undefinedIfEmpty(sessionId.value) })
                if (clearInput) {
                    const currentLogin = loginRef.current;
                    const currentPassword = passwordRef.current
                    const currentSessionId = sessionIdRef.current
                    if (currentLogin !== undefined && currentLogin !== null && currentPassword != undefined && currentSessionId !== undefined && currentSessionId !== null) {
                        currentLogin.value = ""
                        currentPassword.value = ""
                        currentSessionId.value = ""
                        currentLogin.focus()
                        updateDisabledState(currentLogin.value, currentPassword.value)
                    }
                }
            }
        }
    }
    const onFormKey = (event: KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter') {
            (event.target as HTMLFormElement).submit()
        }
    }
    return (
        <form onKeyDown={onFormKey} onSubmit={onFormSubmit} onChange={onFormChange}>
            <InputGroup className='bot-cred-input'>
                <FormControl disabled={readonly} name='login' onPaste={onLoginPaste} onChange={onLoginChange} ref={loginRef as React.RefObject<any>} placeholder='login'></FormControl>
                <InputGroup.Text>:</InputGroup.Text>
                <FormControl disabled={readonly} name='password' onChange={onPasswordChange} onKeyDown={onPasswordKey} ref={passwordRef as React.RefObject<any>} placeholder='password'></FormControl>
                <InputGroup.Text>:</InputGroup.Text>
                <FormControl disabled={readonly} name='sessionId' onKeyDown={onSessionIdKey} ref={sessionIdRef as React.RefObject<any>} placeholder='session id (optional)'></FormControl>
                {props.enableAdd && <Button disabled={disabled} variant='success' type='submit'><PlusLg /></Button>}
            </InputGroup>
        </form>
    )
}