import {ReactNode} from "react";
import {Row} from "react-bootstrap";

import './Styles/BlockTitle.css'
import {ArrowLeftShort, XLg} from "react-bootstrap-icons";

export interface BlockTitleProps {
    title: ReactNode | string
    description?: ReactNode | string | null,
    closeType?: 'close' | 'back',
    onClose?: () => void
}
export const BlockTitle = (props: BlockTitleProps) => {
    const onClose = () => {
        if (props.onClose !== undefined) {
            props.onClose()
        }
    }
    const showClose = props.closeType !== undefined && props.closeType === 'close'
    const showBack = props.closeType !== undefined && props.closeType === 'back'
    return (
        <Row>
            <div>
                <div className='block-wrapper'>
                    <div className="block-wrapper-row">
                        <div className='block-title'>
                            {showBack && <ArrowLeftShort className='block-back' size='1.5em' onClick={onClose} />}
                            {props.title}
                        </div>
                        {showClose && <div className='block-close'>
                            <XLg onClick={onClose} />
                        </div>}
                    </div>
                    {props.description !== null && props.description !== undefined && <div className='block-description'>
                        {props.description}
                    </div>}
                </div>
            </div>
        </Row>
    )
}