import {ChangeEvent, useEffect, useState} from "react";
import {FieldState} from "../../Utils/FieldState";
import {FormControl, FormLabel, InputGroup} from "react-bootstrap";
import {SimpleTooltip} from "../../Utils/SimpleTooltip";
import {CheckLg, XLg} from "react-bootstrap-icons";
import {useCreateTask} from "./CreateTaskContext";
import { Buffer } from "buffer";

export const CreateSetAvatarTask = () => {
    const createTask = useCreateTask()

    const [avatarValid, setAvatarValid] = useState<FieldState<string>>({ valid: false, message: 'You need to select avatar file' })
    const onAvatarChange = (event: ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files
        if (files === null || files.length === 0) {
            setAvatarValid({ valid: false, message: 'You need to select avatar file' })
            return;
        }
        const file = files[0]
        if (file.type !== 'image/png') {
            setAvatarValid({ valid: false, message: 'Only png files are acceptable' })
            return;
        }
        file.arrayBuffer()
            .then(e => Buffer.from(e).toString('base64'))
            .then(e => setAvatarValid({ valid: true, message: `${(e.length / 1024).toFixed(1)}kB file`, data: e }))
            .catch(e => setAvatarValid({ valid: false, message: `Can't read file: ${e.message}` }))
    }

    useEffect(() => {
        if (avatarValid.valid) {
            createTask.dispatchData({ avatar_base64: avatarValid.data })
        } else {
            createTask.dispatchInvalid()
        }
    }, [avatarValid])

    return (
        <div>
            <FormLabel>Avatar file</FormLabel>
            <InputGroup>
                <FormControl onChange={onAvatarChange} type='file' />
                <InputGroup.Text>
                    <SimpleTooltip tooltip={avatarValid.message}>
                        {avatarValid.valid ? <CheckLg color='green'/> : <XLg color='red'/>}
                    </SimpleTooltip>
                </InputGroup.Text>
            </InputGroup>
        </div>
    )
}