import {Modal} from "react-bootstrap";
import {TaskDetails} from "./TaskDetails";

export interface TaskDetailsModalProps {
    taskId: number,
    onClose: () => void
}


export const TaskDetailsModal = (props: TaskDetailsModalProps) => {
    return (
        <Modal fullscreen='lg-down' size='lg' show={true}>
            <Modal.Body>
                <TaskDetails taskId={props.taskId} closeType='close' onClose={props.onClose} />
            </Modal.Body>
        </Modal>
    )
}