import {BotInfo, ProxyInfo, ProxyStatus, SetBotProxyRequest, SetBotProxyResponse} from "../Core/Model";
import {Badge, Button, Modal, Spinner} from "react-bootstrap";
import {MultiselectPaginatedDropdown} from "../Utils/MultiselectPaginatedDropdown";
import {useApi} from "../Core/ApiContextProvider";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {useReducer} from "react";
import {Check2Circle, ExclamationCircle} from "react-bootstrap-icons";

export interface BotChangeProxyProps {
    bot: BotInfo,
    // eslint-disable-next-line no-unused-vars
    onClose: (changed: boolean) => void
}

interface BotChangeProxyState {
    selected: ProxyInfo | null
    update: CallState<SetBotProxyResponse>
}

type BotChangeProxyEvent = {
    type: 'CALL_UPDATE',
    call: 'update',
    update: CallUpdate
} | {
    type: 'PROXY_SELECT',
    proxy: ProxyInfo | null
}

function newState(): BotChangeProxyState {
    return {
        selected: null,
        update: newCall()
    }
}

export const BotChangeProxyModal = (props: BotChangeProxyProps) => {
    const api = useApi()
    const [state, dispatchState] = useReducer((state: BotChangeProxyState, event: BotChangeProxyEvent) => {
        if (event.type === 'CALL_UPDATE') {
            switch (event.call) {
                case 'update': {
                    return { ...state, update: callDispatch(state.update, event.update) }
                }
            }
        } else if (event.type === 'PROXY_SELECT') {
            return { ...state, selected: event.proxy }
        }
        return state
    }, newState())
    const onProxyChange = (proxies: ProxyInfo[]) => {
        const proxy = proxies.length > 0 ? proxies[0] : null
        dispatchState({ type: 'PROXY_SELECT', proxy: proxy })
    }
    const onUpdate = () => {
        const proxy = state.selected
        if (proxy === null) {
            return
        }
        const request: SetBotProxyRequest = { proxy_id: proxy.id }
        dispatchState({ type: 'CALL_UPDATE', call: 'update', update: { type: 'LOADING', message: 'Updating proxy...' } })
        api.botsProxy(props.bot.bot_id, request)
            .then(botList => dispatchState({ type: 'CALL_UPDATE', call: 'update', update: { type: 'DATA', data: botList } }))
            .catch(error => dispatchState({ type: 'CALL_UPDATE', call: 'update', update: { type: 'ERROR', error: error } }))
    }
    const onClose = () => {
        props.onClose(state.update.data !== null)
    }
    const getContent = () => {
        if (state.update.loading) {
            return (
                <div className='loading-wrapper'>
                    <Spinner/>
                    {state.update.message !== null && <span className='loading-message'>
                        {state.update.message}
                   </span>}
                </div>
            )
        }
        if (state.update.data) {
            return (
                <div className='success-message'>
                    <Check2Circle color='green' />
                    <div>Proxy successfully update for bot <b>{props.bot.bot_id}</b></div>
                </div>
            )
        }
        return (
            <>
                <MultiselectPaginatedDropdown empty={`Select proxy`} single={(e: ProxyInfo) => <div className='full-width text-start'>Proxy #{e.id} {e.data}</div>} multiple={(e: ProxyInfo[]) => `${e.length} proxies`} fetch={(limit, offset) => {
                    return api.proxiesList(offset, limit, [ ProxyStatus.ACTIVE ])
                        .then(e => {
                            return { data: e.proxies, total_count: e.total_count }
                        })
                }} itemKey={e => e.id} label={e => {
                    return (
                        <div>
                            <div className='fw-bold'>Proxy {e.id} {props.bot.proxy?.id === e.id && <Badge pill bg='success'>Current</Badge>}</div>
                            <div>{e.data}</div>
                        </div>
                    )
                }} multiselect={false} onChange={onProxyChange} />
                {state.update.error &&
                    <div className='error-message mt-2'>
                        <ExclamationCircle size='1.2em' color='red' />
                        <div>
                            <div>Failed to update bot proxy: {state.update.error.message}</div>
                        </div>
                    </div>}
            </>
        )
    }
    return (
        <Modal show={true} size='lg' fullscreen='lg-down'>
            <Modal.Header>
                <Modal.Title>
                    Change proxy for bot {props.bot.bot_id}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {getContent()}
            </Modal.Body>
            <Modal.Footer>
                {!state.update.data && <Button disabled={state.selected === null || state.update.loading} onClick={onUpdate}>Update</Button>}
                <Button disabled={state.update.loading} onClick={onClose}>Close</Button>
            </Modal.Footer>
        </Modal>
    )
}