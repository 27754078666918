import {ApiError} from "../Core/Api";
import {PaginationDirection} from "./Pagination";

export interface Pagination {
    offset: number,
    limit: number,
    limits: number[]
}

export interface CallState<T, MD = {}> {
    loading: boolean
    message: null | string
    data: null | T,
    error: null | ApiError,
    pagination: Pagination,
    refreshIndicator: boolean
    metadata: MD
}

export type CallUpdate =
    {
        type: 'LOADING',
        message: string
    } |
    {
        type: 'DATA',
        data: any
    } |
    {
        type: 'ERROR',
        error: ApiError
    } |
    {
        type: 'PAGINATION',
        direction: PaginationDirection
    } |
    {
        type: 'REFRESH'
    } |
    {
        type: 'SET_METADATA',
        metadata?: any
    } |
    {
        type: 'LIMIT',
        limit: number
    }

export function newCallWithMetadata<T, MD>(metadata: MD, limit?: number): CallState<T, MD> {
    return {
        loading: false,
        message: null,
        data: null,
        error: null,
        pagination: { offset: 0, limit: limit ?? 10, limits: [10, 20, 50, 100] },
        refreshIndicator: false,
        metadata: metadata
    }
}

export function newCall<T>(limit?: number): CallState<T> {
    return newCallWithMetadata({}, limit)
}

export function callDispatch<T, MD>(state: CallState<T, MD>, update: CallUpdate): CallState<T, MD> {
    switch (update.type) {
        case "LOADING":
            return { ...state, loading: true, message: update.message, error: null }
        case "DATA":
            return { ...state, loading: false, message: null, data: update.data, error: null }
        case "ERROR":
            return { ...state, loading: false, message: null, error: update.error}
        case "PAGINATION":
            return { ...state, pagination: { ...state.pagination, offset: update.direction === 'next' ? state.pagination.offset + state.pagination.limit : state.pagination.offset - state.pagination.limit } }
        case 'REFRESH':
            return { ...state, refreshIndicator: !state.refreshIndicator }
        case 'SET_METADATA':
            return { ...state, metadata: update.metadata }
        case 'LIMIT':
            return { ...state, pagination: { ...state.pagination, limit: update.limit, offset: 0 } }
    }
}