import {Alert, Button, Dropdown, Modal, Spinner, Table} from "react-bootstrap";
import {Trash, X} from "react-bootstrap-icons";
import {useEffect, useReducer} from "react";
import {BotCreateRequest, CollisionResolveStrategy, CreateBotCredentials} from "../Core/Model";
import {BotCredentialsInput} from "./BotCredentialsInput";
import {callDispatch, CallState, CallUpdate, newCall} from "../Utils/Calls";
import {useApi} from "../Core/ApiContextProvider";

export interface CreateBotDialogProps {
    show: boolean,
    initialBots?: CreateBotCredentials[],
    size?: "sm" | "lg" | "xl",
    // eslint-disable-next-line no-unused-vars
    onClose: (changed: boolean) => void
}

interface CreateBotState {
    create: CallState<boolean>,
    resolveStrategy: CollisionResolveStrategy,
    bots: CreateBotCredentials[]
}

type CreateBotEvent =
    {
        type: 'CALL_UPDATE',
        data: CallUpdate,
        call: 'create'
    } |
    {
        type: 'RESOLVE_STRATEGY_SET',
        data: CollisionResolveStrategy
    } |
    {
        type: 'RESET'
    } |
    {
        type: 'BOTS_ADD',
        bots: CreateBotCredentials[]
    } |
    {
        type: 'BOT_REMOVE',
        index: number
    }

function newState(initialBots?: CreateBotCredentials[]): CreateBotState {
    return {
        create: newCall(),
        resolveStrategy: CollisionResolveStrategy.SKIP,
        bots: initialBots ?? []
    }
}

interface CreateBotRowProps {
    bot: CreateBotCredentials,
    index: number,
    // eslint-disable-next-line no-unused-vars
    removeBot: (index: number) => void
}

const CreateBotRow = ({ bot, index, removeBot }: CreateBotRowProps) => {
    return (
        <tr>
            <td className='bot-create-table-cell'>
                {bot.login}
            </td>
            <td className='bot-create-table-cell'>
                {bot.password}
            </td>
            <td className='bot-create-table-cell'>
                {bot.session_key}
            </td>
            <td className='bot-create-table-cell-end'>
                <Button size='sm' variant='outline-danger' onClick={() => removeBot(index)}><Trash /></Button>
            </td>
        </tr>
    )
}

interface CollisionResolveDropdownProps {
    disabled?: boolean
    prefix?: string
    value: CollisionResolveStrategy,
    // eslint-disable-next-line no-unused-vars
    onChange: (value: CollisionResolveStrategy) => void
}

const CollisionResolveDropdown = (props: CollisionResolveDropdownProps) => {
    const prefix = props.prefix === undefined ? 'Resolve collisions:' : props.prefix
    const disabled = props.disabled === undefined ? false : props.disabled;
    return (
        <Dropdown>
            <Dropdown.Toggle disabled={disabled}>{prefix} {props.value.toString()}</Dropdown.Toggle>
            <Dropdown.Menu>
                {
                    Object.entries(CollisionResolveStrategy).map(([key, value]) => {
                        return (
                            <Dropdown.Item onClick={() => props.onChange(value as CollisionResolveStrategy)} key={key}>{value}</Dropdown.Item>
                        )
                    })
                }
            </Dropdown.Menu>
        </Dropdown>
    )
}

export const CreateBotDialog = (props: CreateBotDialogProps) => {
    const api = useApi()
    const [state, dispatchState] = useReducer((state: CreateBotState, event: CreateBotEvent) => {
        switch (event.type) {
            case "BOTS_ADD": {
                return { ...state, bots: [ ...state.bots, ...event.bots ] }
            }
            case "BOT_REMOVE": {
                return { ...state, bots: state.bots.filter((_, idx) => idx !== event.index) }
            }
            case 'CALL_UPDATE': {
                switch (event.call) {
                    case 'create':
                        return {...state, create: callDispatch(state.create, event.data) };
                }
                break;
            }
            case 'RESOLVE_STRATEGY_SET': {
                return {...state, resolveStrategy: event.data}
            }
            case 'RESET': {
                return newState()
            }
        }
        return state;
    }, newState(props.initialBots));
    const onClose = (changed: boolean) => {
        dispatchState({ type: 'RESET' })
        props.onClose(changed)
    }
    const addBot = (bot: CreateBotCredentials) => {
        dispatchState({ type: 'BOTS_ADD', bots: [ bot ] })
        return true
    }
    const removeBot = (index: number) => {
        dispatchState({ type: 'BOT_REMOVE', index: index })
    }
    const onSubmit = () => {
        const bots = state.bots
        if (bots.length === 0) {
            return
        }
        dispatchState({ type: 'CALL_UPDATE', call: 'create', data: { type: 'LOADING', message: `Add ${bots.length} bot(s)...` } })
        const request: BotCreateRequest = {
            bot_creds: bots,
            collision_resolve_strategy: state.resolveStrategy
        }
        api.botsAdd(request)
            .then(e => dispatchState({ type: 'CALL_UPDATE', call: 'create', data: { type: 'DATA', data: e} }))
            .catch(e => dispatchState({ type: 'CALL_UPDATE', call: 'create', data: { type: 'ERROR', error: e} }))
    }
    const onSelectStrategy = (strategy: CollisionResolveStrategy) => {
        dispatchState({ type: 'RESOLVE_STRATEGY_SET', data: strategy })
    }
    const showInputForm = !state.create.loading && state.create.data === null;
    const showLoading = state.create.loading && state.create.data === null;
    const showSuccessMessage = !state.create.loading && state.create.data !== null;
    const showFormControls = state.create.loading || state.create.data === null || state.create.error !== null;
    useEffect(() => {
        if (props.show) {
            dispatchState({ type: 'BOTS_ADD', bots: props.initialBots ?? [] })
        }
    }, [props.show, props.initialBots])
    return (
        <Modal show={props.show} size={props.size}>
            <Modal.Header className='app-modal-header'>
                <Modal.Title>
                    <div className='app-modal-title'>
                        Add bots
                    </div>
                </Modal.Title>
                <div className='app-modal-close' onClick={() => onClose(state.create.data !== null)}>
                    <X size='2em' />
                </div>
            </Modal.Header>
            <Modal.Body>
                {showInputForm ? <div>
                    <div className='modal-table'>
                        <Table size='sm'>
                            <thead>
                                <tr>
                                    <th className='bot-create-table-cell'>Login</th>
                                    <th className='bot-create-table-cell'>Password</th>
                                    <th className='bot-create-table-cell'>Session ID</th>
                                    <th className='bot-create-table-cell'></th>
                                </tr>
                            </thead>
                            <tbody>
                            {state.bots.map((bot, index) => <CreateBotRow key={`create-bot-${index}`} bot={bot} index={index} removeBot={removeBot} />)}
                            </tbody>
                        </Table>
                    </div>
                    <BotCredentialsInput enableAdd={true} readonly={false} onAdd={addBot} />
                    {state.create.error !== null ?
                        <div className='create-error'>
                            <Alert variant='danger'>
                                Failed to add bots. {state.create.error.message}
                            </Alert>
                        </div> : null
                    }
                </div> : null}
                {showLoading ?
                    <div className='loading-wrapper'>
                        <Spinner />
                        {state.create.message !== null && <span className='loading-message'>
                        {state.create.message}
                    </span>}
                    </div> : null
                }
                {showSuccessMessage ?
                    <div className='create-success'>
                        Bots are created successfully
                    </div> : null
                }
            </Modal.Body>
            {showFormControls ? <Modal.Footer>
                <CollisionResolveDropdown disabled={state.create.loading} value={state.resolveStrategy} onChange={onSelectStrategy} />
                <Button onClick={() => onSubmit()} variant='success' disabled={state.create.loading || state.bots.length === 0}>
                    {state.bots.length === 0 ? 'Submit' : `Add ${state.bots.length} bot(s)`}
                </Button>
                <Button variant='outline-danger' disabled={state.create.loading} onClick={() => onClose(false)}>
                    Cancel
                </Button>
            </Modal.Footer> : null}
        </Modal>
    )
}