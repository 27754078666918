import {Col, Nav, Row, Tab} from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Styles/Dashboard.css'
import {BlockTitle} from "../Utils/BlockTitle";
import {ReactNode, useEffect, useState} from "react";
import {Globe2, Icon, ListCheck, PeopleFill, Plus, Robot} from "react-bootstrap-icons";
import {BotList} from "../Bots/BotList";
import {TaskList} from "../Tasks/TaskList";
import {CreateTask} from "../Tasks/Create/CreateTask";
import {useCache} from "../Utils/UserCache";
import {GroupList} from "../Groups/GroupList";
import {ProxyList} from "../Proxy/ProxyList";

interface DashboardPage {
    key: string,
    title: string,
    description: string,
    element: ReactNode,
    icon?: Icon | null
}

const dashboardPages: DashboardPage[] = [
    {
        key: 'bots',
        title: 'Bots',
        description: 'Bots list',
        element: <BotList />,
        icon: Robot
    },
    {
        key: 'bot_groups',
        title: 'Bot Groups',
        description: 'Bot groups',
        element: <GroupList />,
        icon: PeopleFill
    },
    {
        key: 'proxy',
        title: 'Proxy',
        description: 'Bot proxies',
        element: <ProxyList />,
        icon: Globe2
    },
    {
        key: 'tasks',
        title: 'Tasks',
        description: 'Tasks status',
        element: <TaskList />,
        icon: ListCheck
    },
    {
        key: 'upload_new',
        title: 'New Task',
        description: 'Create new task for bots',
        element: <CreateTask />,
        icon: Plus
    }
]

export const Dashboard = () => {
    const cache = useCache()
    const [activeKey, setActiveKey] = useState<string>(cache.value('dashboard_page', 'bots'))

    const onSelect = (eventKey: string | null) => {
        if (eventKey !== null) {
            setActiveKey(eventKey)
        }
    }

    const navItems: ReactNode[] = [];
    const navPages: ReactNode[] = [];

    useEffect(() => {
        cache.setValue('dashboard_page', activeKey)
    }, [activeKey])

    dashboardPages.forEach(page => {
        navItems.push(
            <Nav.Item key={`nav-item-${page.key}`}>
                <Nav.Link eventKey={page.key}>
                    <div className='app-tab-left-nav-item'>
                        {page.icon !== null && page.icon !== undefined ? <page.icon size={'1.2em'} /> : null}
                        {page.title}
                    </div>
                </Nav.Link>
            </Nav.Item>
        )
        navPages.push(
            <Tab.Pane eventKey={page.key} key={`pane-${page.key}`}>
                <div className='app-tab-content'>
                    <BlockTitle title={`Dashboard > ${page.title}`} description={page.description} />
                    <div className='app-tab-content-wrapper'>
                        {page.element}
                    </div>
                </div>
                <div className='app-tab-content-footer'>
                    <span>Dashboard</span>
                </div>
            </Tab.Pane>
        )
    })

    return (
        <Tab.Container onSelect={onSelect} activeKey={activeKey}>
            <Row>
                <Col sm={3} className='app-tab-left-nav'>
                    <Nav variant={'pills'} className={'flex-column'}>
                        {navItems}
                    </Nav>
                </Col>
                <Col sm={9} className='app-tab-right-content'>
                    <Tab.Content>
                        {navPages}
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}