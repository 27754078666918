import React, {ReactNode, MouseEvent, ForwardedRef} from "react";
import {ThreeDots} from "react-bootstrap-icons";
import {Button} from "react-bootstrap";

export const DotsToggle = React.forwardRef(({ children, onClick }: { children?: ReactNode, onClick: (event: MouseEvent<HTMLElement>) => void }, ref: ForwardedRef<HTMLAnchorElement>) => (
    <span
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <Button size='sm' variant='outline-primary'><ThreeDots /></Button>
    </span>
));