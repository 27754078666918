import {ChangeEvent, useEffect, useState} from "react";
import {FieldState} from "../../Utils/FieldState";
import {FormControl, InputGroup} from "react-bootstrap";
import {SimpleTooltip} from "../../Utils/SimpleTooltip";
import {CheckLg, XLg} from "react-bootstrap-icons";
import {useCreateTask} from "./CreateTaskContext";

export const CreateSetBioTask = () => {
    const createTask = useCreateTask()

    const [bioValid, setBioValid] = useState<FieldState<string>>({ valid: false, message: 'You need to enter bio text' })
    const onBioChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.trim()
        if (value.length === 0) {
            setBioValid({ valid: false, message: 'You need to enter bio text' })
            return
        }
        setBioValid({ valid: true, message: 'Ok', data: value })
    }

    useEffect(() => {
        if (bioValid.valid) {
            createTask.dispatchData({ bio: bioValid.data })
        } else {
            createTask.dispatchInvalid()
        }
    }, [bioValid])

    return (
        <div>
            <InputGroup>
                <InputGroup.Text>Bio</InputGroup.Text>
                <FormControl onChange={onBioChange} as='textarea' type='text' placeholder='Text...' rows={3} />
                <InputGroup.Text>
                    <SimpleTooltip tooltip={bioValid.message}>
                        {bioValid.valid ? <CheckLg color='green'/> : <XLg color='red'/>}
                    </SimpleTooltip>
                </InputGroup.Text>
            </InputGroup>
        </div>
    )
}