import {Navigate, useNavigate, useParams} from "react-router-dom";
import {TaskDetails} from "./TaskDetails";

export const TaskDetailsPage = () => {
    const { taskId } = useParams()
    const navigate = useNavigate()
    if (taskId === null || taskId === undefined) {
        return <Navigate to='/' />
    }

    const id = Number(taskId)
    if (Number.isNaN(id)) {
        return <Navigate to='/' />
    }

    return (
        <TaskDetails closeType='back' taskId={id} onClose={() => navigate(-1)}/>
    )
}