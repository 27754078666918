import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {Placement} from "react-bootstrap/types";
import {ReactNode} from "react";

export interface SimpleTooltipProps {
    placement?: Placement,
    tooltip: ReactNode | string,
    children?: ReactNode | string | null
}
export const SimpleTooltip = (props: SimpleTooltipProps) => {
    return (
        <OverlayTrigger
            placement={props.placement}
            overlay={<Tooltip>{props.tooltip}</Tooltip>}>
            <span>{props.children}</span>
        </OverlayTrigger>
    )
}