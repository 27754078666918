import {useMemo} from "react";

import './Styles/ShowMore.css'

export interface ShowMoreProps {
    value: string,
    limit?: number
}
export const ShowMore = (props: ShowMoreProps) => {
    const [value, more] = useMemo(() => {
        const limit = props.limit ?? 30
        const value = props.value.slice(0, limit)
        if (props.value.length > limit) {
            return [
                <span key='value'>{value}...</span>,
                <div key='more' className='show-more-link'>...and more {props.value.length - limit} characters...</div>
            ]
        }
        return [<span key='value'>{value}</span>, null]
    }, [props.limit, props.value])
    return (
        <div className='show-more'>
            {value}
            {more}
        </div>
    )
}